import React from "react";
import {Typography} from "antd";
const { Title } = Typography;

const Dashboard = () => {
    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-6 col-lg-4">
                            <Title level={2} className="text-main mb-2">
                                {" "}
                                Dashboard{" "}
                            </Title>
                        </div>
                    </div>
                </div>
            </div>

            <p>
                Welcome to the dashboard. This is the first page you will see
            </p>
        </div>
    );
};

export default Dashboard;
