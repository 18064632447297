import React from "react";
import { ProtectedRoutesAdmin } from "./components/control-sections/auth/ProtectedRoutes";
import { Routes, Route } from "react-router-dom";
import Login from "./components/control-sections/auth/Login";
import Roles from "./components/control-sections/roles/Roles";
import AddRole from "./components/control-sections/roles/AddRole";
import EditRole from "./components/control-sections/roles/EditRole";
import Statistics from "./components/Investor-sections/statistics/Statistics";
import AddStatistic from "./components/Investor-sections/statistics/AddStatistic";
import EditStatistic from "./components/Investor-sections/statistics/EditStatistic";
import Admins from "./components/control-sections/admins/Admins";
import AddAdmin from "./components/control-sections/admins/AddAdmin";
import EditAdmin from "./components/control-sections/admins/EditAdmin";
import UnitTypes from "./components/Investor-sections/unit_types/UnitTypes";
import AddUnitType from "./components/Investor-sections/unit_types/AddUnitType";
import EditUnitType from "./components/Investor-sections/unit_types/EditUnitType";
import Settings from "./components/Investor-sections/settings/Settings";
import EditPage from "./components/control-sections/pages/EditPage";
import Pages from "./components/control-sections/pages/Pages";
import Menus from "./components/Investor-sections/menus/Menus";
import AddMenu from "./components/Investor-sections/menus/AddMenu";
import InvestmentAnswerCategories from "./components/Investor-sections/invest_answer_categories/InvestAnswerCategories";
import AddInvestAnswerCategory from "./components/Investor-sections/invest_answer_categories/AddInvestAnswerCategory";
import EditInvestAnswerCategory from "./components/Investor-sections/invest_answer_categories/EditInvestAnswerCategory";
import EditMenu from "./components/Investor-sections/menus/EditMenu";
import InvestQuestions from "./components/Investor-sections/invest_questions/InvestQuestions";
import AddInvestQuestion from "./components/Investor-sections/invest_questions/AddInvestQuestion";
import EditInvestQuestion from "./components/Investor-sections/invest_questions/EditInvestQuestion";
import Feedbacks from "./components/Investor-sections/feedbacks/Feedbacks";
import EditFeedback from "./components/Investor-sections/feedbacks/EditFeedback";
import ChangePassword from "./components/control-sections/profile/ChangePassword";
import Companies from "./components/Investor-sections/companies/Companies";
import AddCompany from "./components/Investor-sections/companies/AddCompany";
import EditCompany from "./components/Investor-sections/companies/EditCompany";
import InvestRequests from "./components/Investor-sections/invest_requests/InvestRequests";
import EditInvestRequest from "./components/Investor-sections/invest_requests/EditInvestRequest";
import ForgetPassword from "./components/control-sections/auth/forget_password";
import Otp from "./components/control-sections/auth/otp";
import ResetPassword from "./components/control-sections/auth/reset_password";
import EditContactMessage from "./components/Investor-sections/contact-messages/EditContactMessage";
import ContactMessages from "./components/Investor-sections/contact-messages/ContactMessages";
import AddStatisticValues from "./components/Investor-sections/statistic-values/AddStatisticValues";
import Users from "./components/Investor-sections/users/Users";
import EditUser from "./components/Investor-sections/users/EditUser";
import Packages from "./components/Investor-sections/packages/Packages";
import StatisticValues from "./components/Investor-sections/statistic-values/StatisticValues";
import AddPackage from "./components/Investor-sections/packages/AddPackage";
import EditPackage from "./components/Investor-sections/packages/EditPackage";
import SubscriptionHistories from "./components/Investor-sections/subscritpion-histories/SubscriptionHistories";
import Cities from "./components/Investor-sections/cities/Cities";
import AddCity from "./components/Investor-sections/cities/AddCity";
import EditCity from "./components/Investor-sections/cities/EditCity";
import Neighborhoods from "./components/Investor-sections/neighborhoods/Neighborhoods";
import EditNeighborhood from "./components/Investor-sections/neighborhoods/EditNeighborhood";
import AddNeighborhood from "./components/Investor-sections/neighborhoods/AddNeighborhood";
import Interests from "./components/Investor-sections/interests/Interests";
import EditStatisticValues from "./components/Investor-sections/statistic-values/EditStatisticValues";
import MessagesTranslations from "./components/control-sections/message-translations/MessagesTranslations";
import EditMessagesTranslation from "./components/control-sections/message-translations/EditMessagesTranslation";
import StatisticCategories from "./components/Investor-sections/statistic-categories/StatisticCategories";
import AddStatisticCategory from "./components/Investor-sections/statistic-categories/AddStatisticCategory";
import EditStatisticCategory from "./components/Investor-sections/statistic-categories/EditStatisticCategory";
import Ratings from "./components/Investor-sections/ratings/Ratings";
import Landmarks from "./components/Investor-sections/landmarks/Landmarks";
import AddLandmark from "./components/Investor-sections/landmarks/AddLandmark";
import EditLandmark from "./components/Investor-sections/landmarks/EditLandmark";
import EstimationQuestions from "./components/Investor-sections/estimation_questions/EstimationQuestions";
import AddEstimationQuestion from "./components/Investor-sections/estimation_questions/AddEstimationQuestion";
import EditEstimationQuestion from "./components/Investor-sections/estimation_questions/EditEstimationQuestion";
import Estimates from "./components/Investor-sections/estimates/Estimates";
import EditEstimate from "./components/Investor-sections/estimates/EditEstimate";
import EstimationMultipliers from "./components/Investor-sections/estimation_multipliers/EstimationMultipliers";
import AddEstimationMultiplier from "./components/Investor-sections/estimation_multipliers/AddEstimationMultiplier";
import Sliders from "./components/Investor-sections/sliders/Sliders";
import AddSlider from "./components/Investor-sections/sliders/AddSlider";
import EditSlider from "./components/Investor-sections/sliders/EditSlider";
import VariationCategories from "./components/Investor-sections/VariationCategories/VariationCategories";
import AddVariationCategories from "./components/Investor-sections/VariationCategories/AddVariationCategories";
import EditVariationCategories from "./components/Investor-sections/VariationCategories/EditVariationCategories";
import Variations from "./components/Investor-sections/Variations/Variations";
import AddVariation from "./components/Investor-sections/Variations/AddVariation";
import EditVariation from "./components/Investor-sections/Variations/EditVariation";
import VariationValues from "./components/Investor-sections/variation-values/VariationValues";
import AddVariationValue from "./components/Investor-sections/variation-values/AddVariationValue";
import EditVariationValue from "./components/Investor-sections/variation-values/EditVariationValue";
import PackagesAgent from "./components/Agent-sections/packages-agent/PackagesAgent";
import AddPackageAgent from "./components/Agent-sections/packages-agent/AddPackageAgent";
import EditPackageAgent from "./components/Agent-sections/packages-agent/EditPackageAgent";
import AgentMenus from "./components/Agent-sections/menus-agent/AgentMenus";
import AddAgentMenu from "./components/Agent-sections/menus-agent/AddAgentMenu";
import EditAgentMenu from "./components/Agent-sections/menus-agent/EditAgentMenu";
import AgentMyAccountMenus from "./components/Agent-sections/AgentMyAccountMenus/AgentMyAccountMenus";
import EditAgentMyAccountMenu from "./components/Agent-sections/AgentMyAccountMenus/EditAgentMyAccountMenu";
import AgentUsers from "./components/Agent-sections/users-agent/AgentUsers";
import EditAgentUser from "./components/Agent-sections/users-agent/EditAgentUser";
import AgentMessages from "./components/Agent-sections/agent-messages/AgentMessages";
import EditAgentMessage from "./components/Agent-sections/agent-messages/EditAgentMessage";
import AgentRatings from "./components/Agent-sections/agent-ratings/AgentRatings";
import AgentSettings from "./components/Agent-sections/settings-agent/AgentSettings";
import AgentWhatsappMessages from "./components/Agent-sections/AgentWhatsappMessages/AgentWhatsappMessages";
import AddAgentWhatsappMessage from "./components/Agent-sections/AgentWhatsappMessages/AddAgentWhatsappMessage";
import EditAgentWhatsappMessage from "./components/Agent-sections/AgentWhatsappMessages/EditAgentWhatsappMessage";
import AgentDropdowns from "./components/Agent-sections/AgentDropdowns/AgentDropdowns";
import AddAgentDropdown from "./components/Agent-sections/AgentDropdowns/AddAgentDropdown";
import EditAgentDropdown from "./components/Agent-sections/AgentDropdowns/EditAgentDropdown";
import AgentReservations from "./components/Agent-sections/Reservation/AgentReservations";
import AddAgentReservation from "./components/Agent-sections/Reservation/AddAgentReservation";
import EditAgentReservation from "./components/Agent-sections/Reservation/EditAgentReservation";
import AgentTrainingCategories from "./components/Agent-sections/agent-training-categories/AgentTrainingCategories";
import AddAgentTrainingCategory from "./components/Agent-sections/agent-training-categories/AddAgentTrainingCategory";
import EditAgentTrainingCategory from "./components/Agent-sections/agent-training-categories/EditAgentTrainingCategory";
import AgentTrainings from "./components/Agent-sections/agent-trainings/AgentTrainings";
import AddAgentTraining from "./components/Agent-sections/agent-trainings/AddAgentTraining";
import EditAgentTraining from "./components/Agent-sections/agent-trainings/EditAgentTraining";
import CampaignPackages from "./components/Agent-sections/campaign-packages/CampaignPackages";
import AddCampaignPackage from "./components/Agent-sections/campaign-packages/AddCampaignPackage";
import EditCampaignPackage from "./components/Agent-sections/campaign-packages/EditCampaignPackage";
import Campaigns from "./components/Agent-sections/campaign/Campaigns";
import AddCampaign from "./components/Agent-sections/campaign/AddCampaign";
import EditCampaign from "./components/Agent-sections/campaign/EditCampaign";
import SlidersAgent from "./components/Agent-sections/sliders-agent/SlidersAgent";
import AddSliderAgent from "./components/Agent-sections/sliders-agent/AddSliderAgent";
import EditSliderAgent from "./components/Agent-sections/sliders-agent/EditSliderAgent";
import AgentSubscribers from "./components/Agent-sections/agent-subscribers/AgentSubscribers";
import AgentCustomers from "./components/Agent-sections/agent-customers/AgentCustomers";
import AddAgentCustomer from "./components/Agent-sections/agent-customers/AddAgentCustomer";
import EditAgentCustomer from "./components/Agent-sections/agent-customers/EditAgentCustomer";
import AgentProjects from "./components/Agent-sections/agent-projects/AgentProjects";
import AddAgentProject from "./components/Agent-sections/agent-projects/AddAgentProject";
import EditAgentProject from "./components/Agent-sections/agent-projects/EditAgentProject";
import CampaignForm from "./components/Agent-sections/campaign/CampaignForm";
import AgentLicences from "./components/Agent-sections/agent-licences/AgentLicences";
import EditAgentLicence from "./components/Agent-sections/agent-licences/EditAgentLicence";
import AgentAccountManagers from "./components/Agent-sections/agent-account-managers/AgentAccountManagers";
import AddAgentAccountManager from "./components/Agent-sections/agent-account-managers/AddAgentAccountManager";
import EditAgentAccountManager from "./components/Agent-sections/agent-account-managers/EditAgentAccountManager";
import AgentPrivateProperties from "./components/Agent-sections/agent-private-properties/AgentPrivateProperties";
import AddAgentPrivateProperty from "./components/Agent-sections/agent-private-properties/AddAgentPrivateProperty";
import EditAgentPrivateProperty from "./components/Agent-sections/agent-private-properties/EditAgentPrivateProperty";
import AgentTeam from "./components/Agent-sections/agent-teams/AgentTeam";
import AgentTeamQuestions from "./components/Agent-sections/agent_team_questions/AgentTeamQuestions";
import EditAgentTeamQuestion from "./components/Agent-sections/agent_team_questions/EditAgentTeamQuestion";
import AddAgentTeamQuestion from "./components/Agent-sections/agent_team_questions/AddAgentTeamQuestion";
import AgentUnits from "./components/Agent-sections/agent-units/AgentUnits";
import EditAgentUnit from "./components/Agent-sections/agent-units/EditAgentUnit";
import AddAgentUnit from "./components/Agent-sections/agent-units/AddAgentUnit";
import AgentProjectQuestions from "./components/Agent-sections/agent_project_questions/AgentProjectQuestions";
import EditAgentProjectQuestion from "./components/Agent-sections/agent_project_questions/EditAgentProjectQuestion";
import AddAgentProjectQuestion from "./components/Agent-sections/agent_project_questions/AddAgentProjectQuestion";
import AddAgentSubscriber from "./components/Agent-sections/agent-subscribers/AddAgentSubscriber";
import Dashboard from "./components/control-sections/layout/Dashboard";
import Unauthorize from "./components/control-sections/layout/403";

const AppRoutes = () => {
    return (
        <div>
            <Routes>
                <Route element={<ProtectedRoutesAdmin />}>
                    <Route path="/roles">
                        <Route path="" element={<Roles />} />
                        <Route path="add" element={<AddRole />} />
                        <Route path="edit/:id" element={<EditRole />} />
                    </Route>
                    <Route path="/admins">
                        <Route path="" element={<Admins />} />
                        <Route path="add" element={<AddAdmin />} />
                        <Route path="edit/:id" element={<EditAdmin />} />
                    </Route>
                    <Route path="/users">
                        <Route path="" element={<Users />} />
                        <Route path="edit/:id" element={<EditUser />} />
                    </Route>
                    <Route path="/agent-users">
                        <Route path="" element={<AgentUsers />} />
                        <Route path="edit/:id" element={<EditAgentUser />} />
                    </Route>
                    <Route path="/statistics">
                        <Route path="" element={<Statistics />} />
                        <Route path="add" element={<AddStatistic />} />
                        <Route path="edit/:id" element={<EditStatistic />} />
                    </Route>
                    <Route path="/unit-types">
                        <Route path="" element={<UnitTypes />} />
                        <Route path="add" element={<AddUnitType />} />
                        <Route path="edit/:id" element={<EditUnitType />} />
                    </Route>
                    <Route path="/agent-whatsapp-messages">
                        <Route path="" element={<AgentWhatsappMessages />} />
                        <Route path="add" element={<AddAgentWhatsappMessage />} />
                        <Route path="edit/:id" element={<EditAgentWhatsappMessage />} />
                    </Route>
                    <Route path="/agent-dropdowns">
                        <Route path="" element={<AgentDropdowns />} />
                        <Route path="add" element={<AddAgentDropdown />} />
                        <Route path="edit/:id" element={<EditAgentDropdown />} />
                    </Route>
                    <Route path="/agent-reservations">
                        <Route path="" element={<AgentReservations />} />
                        <Route path="add" element={<AddAgentReservation />} />
                        <Route path="edit/:id" element={<EditAgentReservation />} />
                    </Route>
                    <Route path="/agent-training-categories">
                        <Route path="" element={<AgentTrainingCategories />} />
                        <Route path="add" element={<AddAgentTrainingCategory />} />
                        <Route path="edit/:id" element={<EditAgentTrainingCategory />} />
                    </Route>
                    <Route path="/agent-trainings">
                        <Route path="" element={<AgentTrainings />} />
                        <Route path="add" element={<AddAgentTraining />} />
                        <Route path="edit/:id" element={<EditAgentTraining />} />
                    </Route>
                    <Route path="/campaign-packages">
                        <Route path="" element={<CampaignPackages />} />
                        <Route path="add" element={<AddCampaignPackage />} />
                        <Route path="edit/:id" element={<EditCampaignPackage />} />
                    </Route>
                    <Route path="/agent-campaigns">
                        <Route path="" element={<Campaigns />} />
                        <Route path="add" element={<AddCampaign />} />
                        <Route path="edit/:id" element={<EditCampaign />} />
                    </Route>
                    <Route path="/pages">
                        <Route path="" element={<Pages />} />
                        <Route path="edit/:id" element={<EditPage />} />
                    </Route>
                    <Route path="/menus">
                        <Route path="" element={<Menus />} />
                        <Route path="add" element={<AddMenu />} />
                        <Route path="edit/:id" element={<EditMenu />} />
                    </Route>
                    <Route path="/agent-menus">
                        <Route path="" element={<AgentMenus />} />
                        <Route path="add" element={<AddAgentMenu />} />
                        <Route path="edit/:id" element={<EditAgentMenu />} />
                    </Route>
                    <Route path="/agent-my-account-menus">
                        <Route path="" element={<AgentMyAccountMenus />} />
                        {/* <Route path="add" element={<AddAgentMenu />} /> */}
                        <Route path="edit/:id" element={<EditAgentMyAccountMenu />} />
                    </Route>
                    <Route path="/invest-answer-categories">
                        <Route path="" element={<InvestmentAnswerCategories />} />
                        <Route path="add" element={<AddInvestAnswerCategory />} />
                        <Route path="edit/:id" element={<EditInvestAnswerCategory />} />
                    </Route>
                    <Route path="/invest-questions">
                        <Route path="" element={<InvestQuestions />} />
                        <Route path="add" element={<AddInvestQuestion />} />
                        <Route path="edit/:id" element={<EditInvestQuestion />} />
                    </Route>
                    <Route path="/statistic-values">
                        <Route path="" element={<StatisticValues />} />
                        <Route path="add" element={<AddStatisticValues />} />
                        <Route path="edit/:id" element={<EditStatisticValues />} />
                    </Route>
                    <Route path="/companies">
                        <Route path="" element={<Companies />} />
                        <Route path="add" element={<AddCompany />} />
                        <Route path="edit/:id" element={<EditCompany />} />
                    </Route>
                    <Route path="/packages">
                        <Route path="" element={<Packages />} />
                        <Route path="add" element={<AddPackage />} />
                        <Route path="edit/:id" element={<EditPackage />} />
                    </Route>
                    <Route path="/agent-packages">
                        <Route path="" element={<PackagesAgent />} />
                        <Route path="add" element={<AddPackageAgent />} />
                        <Route path="edit/:id" element={<EditPackageAgent />} />
                    </Route>
                    <Route path="/cities">
                        <Route path="" element={<Cities />} />
                        <Route path="add" element={<AddCity />} />
                        <Route path="edit/:id" element={<EditCity />} />
                    </Route>
                    <Route path="/neighborhoods">
                        <Route path="" element={<Neighborhoods />} />
                        <Route path="add" element={<AddNeighborhood />} />
                        <Route path="edit/:id" element={<EditNeighborhood />} />
                    </Route>
                    <Route path="/landmarks">
                        <Route path="" element={<Landmarks />} />
                        <Route path="add" element={<AddLandmark />} />
                        <Route path="edit/:id" element={<EditLandmark />} />
                    </Route>
                    <Route path="/invest-requests">
                        <Route path="" element={<InvestRequests />} />
                        <Route path="edit/:id" element={<EditInvestRequest />} />
                    </Route>
                    <Route path="/feedbacks">
                        <Route path="" element={<Feedbacks />} />
                        <Route path="edit/:id" element={<EditFeedback />} />
                    </Route>
                    <Route path="/contact-messages">
                        <Route path="" element={<ContactMessages />} />
                        <Route path="edit/:id" element={<EditContactMessage />} />
                    </Route>
                    <Route path="/agent-messages">
                        <Route path="" element={<AgentMessages />} />
                        <Route path="edit/:id" element={<EditAgentMessage />} />
                    </Route>
                    <Route path="/messages-translations">
                        <Route path="" element={<MessagesTranslations />} />
                        <Route path="edit/:id" element={<EditMessagesTranslation />} />
                    </Route>

                    <Route path="/statistic-categories">
                        <Route path="" element={<StatisticCategories />} />
                        <Route path="add" element={<AddStatisticCategory />} />
                        <Route path="edit/:id" element={<EditStatisticCategory />} />
                    </Route>
                    <Route path="/estimation-questions">
                        <Route path="" element={<EstimationQuestions />} />
                        <Route path="add" element={<AddEstimationQuestion />} />
                        <Route path="edit/:id" element={<EditEstimationQuestion />} />
                    </Route>

                    <Route path="/sliders">
                        <Route path="" element={<Sliders />} />
                        <Route path="add" element={<AddSlider />} />
                        <Route path="edit/:id" element={<EditSlider />} />
                    </Route>
                    <Route path="/sliders-agent">
                        <Route path="" element={<SlidersAgent />} />
                        <Route path="add" element={<AddSliderAgent />} />
                        <Route path="edit/:id" element={<EditSliderAgent />} />
                    </Route>
                    <Route path="/variation-categories">
                        <Route path="" element={<VariationCategories />} />
                        <Route path="add" element={<AddVariationCategories />} />
                        <Route path="edit/:id" element={<EditVariationCategories />} />
                    </Route>
                    <Route path="/variations">
                        <Route path="" element={<Variations />} />
                        <Route path="add" element={<AddVariation />} />
                        <Route path="edit/:id" element={<EditVariation />} />
                    </Route>
                    <Route path="/variation-values">
                        <Route path="" element={<VariationValues />} />
                        <Route path="add" element={<AddVariationValue />} />
                        <Route path="edit/:id" element={<EditVariationValue />} />
                    </Route>

                    <Route path="/estimates">
                        <Route path="" element={<Estimates />} />
                        <Route path="edit/:id" element={<EditEstimate />} />
                    </Route>
                    <Route path="/estimation-multipliers">
                        <Route path="" element={<EstimationMultipliers />} />
                        <Route path="add" element={<AddEstimationMultiplier />} />
                    </Route>
                    <Route path="/agent-customers">
                        <Route path="" element={<AgentCustomers />} />
                        <Route path="add" element={<AddAgentCustomer />} />
                        <Route path="edit/:id" element={<EditAgentCustomer />} />
                    </Route>
                    <Route path="/agent-projects">
                        <Route path="" element={<AgentProjects />} />
                        <Route path="add" element={<AddAgentProject />} />
                        <Route path="edit/:id" element={<EditAgentProject />} />
                    </Route>
                    <Route path="/agent-units">
                        <Route path="" element={<AgentUnits />} />
                        <Route path="add" element={<AddAgentUnit />} />
                        <Route path="edit/:id" element={<EditAgentUnit />} />
                    </Route>
                    <Route path="/agent-project-questions">
                        <Route path="" element={<AgentProjectQuestions />} />
                        <Route path="add" element={<AddAgentProjectQuestion />} />
                        <Route path="edit/:id" element={<EditAgentProjectQuestion />} />
                    </Route>
                    <Route path="/agent-account-managers">
                        <Route path="" element={<AgentAccountManagers />} />
                        <Route path="add" element={<AddAgentAccountManager />} />
                        <Route path="edit/:id" element={<EditAgentAccountManager />} />
                    </Route>
                    <Route path="/agent-private-properties">
                        <Route path="" element={<AgentPrivateProperties />} />
                        <Route path="add" element={<AddAgentPrivateProperty />} />
                        <Route path="edit/:id" element={<EditAgentPrivateProperty />} />
                    </Route>
                    <Route path="/agent-licences">
                        <Route path="" element={<AgentLicences />} />
                        <Route path="edit/:id" element={<EditAgentLicence />} />
                    </Route>

                    <Route path="/settings" element={<Settings />} />
                    <Route path="/agent-settings" element={<AgentSettings />} />
                    <Route path="/subscription-histories" element={<SubscriptionHistories />} />
                    <Route path="/agent-subscribers" element={<AgentSubscribers />} />
                    <Route path="/agent-subscribers/add" element={<AddAgentSubscriber />} />
                    <Route path="/interests" element={<Interests />} />
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/ratings" element={<Ratings />} />
                    <Route path="/agent-ratings" element={<AgentRatings />} />
                    <Route path="/agent-teams" element={<AgentTeam />} />

                    <Route path="/agent-team-questions">
                        <Route path="" element={<AgentTeamQuestions />} />
                        <Route path="add" element={<AddAgentTeamQuestion />} />
                        <Route path="edit/:id" element={<EditAgentTeamQuestion />} />
                    </Route>
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/forget-password" element={<ForgetPassword />} />
                <Route path="/otp" element={<Otp />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/campaigns/:lang/:id" element={<CampaignForm />} />
                <Route path="/403" element={<Unauthorize />} />
            </Routes>
        </div>
    );
};

export default AppRoutes;
