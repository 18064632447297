import React from "react";
import { Typography, Button, Result } from "antd";
import { Link } from "react-router-dom";

const { Title } = Typography;

const Unauthorize = () => {
    return (
        <div className="unauthorize-page">
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={
                    <Link to="/">
                        <Button type="primary">Back to Home</Button>
                    </Link>
                }
            />
        </div>
    );
};

export default Unauthorize;
